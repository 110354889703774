import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faUser, faScroll, faRocket, faPenToSquare, faEnvelope, faEye  } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faInstagram, faDiscord } from "@fortawesome/free-brands-svg-icons";

const skills = [
    ["HTML", 100],
    ["CSS", 100],
    ["Tailwind", 100],
    ["Lua", 100],
    ["JavaScript", 90],
    ["TypeScript", 90],
    ["React", 70],
    ["NodeJS", 80],
    ["Express", 90],
    ["MongoDB", 90],
    ["SQL", 90],
    ["Python", 75],
    ["PHP", 75],
    ["ThreeJS", 45],
    ["JQuery", 85],
    ["Figma", 60],
    ["Jira", 85],
    ["Linux", 80],
    ["Git", 90],
]

const Main: React.FC = () => {
    const openInNewTab = (url: string) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const onClickUrl = (url: string) => {
        return () => openInNewTab(url)
    }

    setTimeout(() => {
        window.scrollTo(0, 0)
    }, 100)
   
    return (
        <>
            <AnimatePresence>
                <div className="fixed w-screen h-screen bg-[#212022]"></div>
                <main className="absolute flex justify-center">
                    <motion.div 
                        className="m-[50px] flex flex-col h-full w-5/12"
                        initial={{ opacity: 0, y: 100 }}
                        animate={{ opacity: 1, y: 0, transition: { duration: 1.5 }}}
                        exit={{ opacity: 0, y: 100 }}
                    >
                        <motion.div 
                            className= "flex"
                            initial={{ opacity: 0, x: -100 }}
                            animate={{ opacity: 1, x: 0, transition: { duration: 2.5 }}}
                            exit={{ opacity: 0, x: -100 }}
                        >
                            <img draggable="false" src = "https://avatars.githubusercontent.com/u/46166826?v=4" className="w-20 h-20 rounded-full" alt="profile" />

                            <div className="flex flex-col">
                                <div className="flex flex-col ml-4 text-white font-semibold shimmer">Chef Theo</div>
                                <div className="flex flex-col ml-4 text-gray-500">Full-stack developer</div>
                                <div className="flex space-x-1">
                                    <div className="transition-all align-middle inline-block ml-4 text-xs text-gray-500 border border-slate-600 rounded-xl p-1 px-3 space-x-2 hover:bg-[#2a292b] hover:text-violet-200">
                                        <FontAwesomeIcon icon={faLocationDot}/>
                                        <span>Bucharest, Romania</span>
                                    </div>
                                </div>
                            </div>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0, transition: { ease: "linear", delay: 0.5, duration: 0.5}}}
                            exit={{ opacity: 0, y: 50 }}
                            className="transition-all flex flex-col mt-8 w-full border border-slate-600/40 rounded-xl p-2 hover:bg-[#2a292b]"
                        >
                            <div className="inline-block text-white font-semibold space-x-2"><FontAwesomeIcon icon={faUser}/><span>About Me</span></div>
                            <div className="flex text-gray-500">I'm a 21 years old full-stack developer with a huge passion ❤️ for creating beautiful and functional aplications and websites. I like everything is connected to technology and development </div>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0, transition: { ease: "linear", delay: 0.8, duration: 0.5}}}
                            exit={{ opacity: 0, y: 50 }}
                            className="transition-all flex flex-col mt-8 w-full border border-slate-600/40 rounded-xl p-2 hover:bg-[#2a292b]"
                        >
                            <div className="inline-block text-white font-semibold space-x-2"><FontAwesomeIcon icon={faRocket}/><span>Projects</span></div>
                            <div className="flex flex-col text-gray-500">I'm opened to any type of projects. If you want to see some of my work, check github profile or just feel free to contact me.</div>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0, transition: { ease: "linear", delay: 1.2, duration: 0.5}}}
                            exit={{ opacity: 0, y: 50 }}
                            className="transition-all flex flex-col mt-8 w-full border border-slate-600/40 rounded-xl p-2 hover:bg-[#2a292b]"
                        >
                            <div className="inline-block text-white font-semibold space-x-2"><FontAwesomeIcon icon={faPenToSquare}/><span>Contact</span></div>
                            <div className="flex flex-col text-gray-500">
                                Feel free to contact me. I will respond as fast as possible!

                                <div className="flex space-x-3 flex-wrap w-full">
                                    <div onClick={onClickUrl("https://github.com/cheftheo")} className="transition-all align-middle inline-block text-xs text-gray-500 border border-slate-600 rounded-xl p-1 px-3 space-x-2 hover:bg-[#2a292b] hover:text-violet-200">
                                        <FontAwesomeIcon icon={faGithub}/>
                                        <span>GitHub</span>
                                    </div>
                                    
                                    <div onClick={onClickUrl("https://www.instagram.com/theom32/")} className="transition-all align-middle inline-block text-xs text-gray-500 border border-slate-600 rounded-xl p-1 px-3 space-x-2 hover:bg-[#2a292b] hover:text-violet-200">
                                        <FontAwesomeIcon icon={faInstagram}/>
                                        <span>Instagram</span>
                                    </div>

                                    <div onClick={() => (alert("mail: marascu.theodor@gmail.com"))} className="transition-all align-middle inline-block text-xs text-gray-500 border border-slate-600 rounded-xl p-1 px-3 space-x-2 hover:bg-[#2a292b] hover:text-violet-200">
                                        <FontAwesomeIcon icon={faEnvelope}/>
                                        <span>Email</span>
                                    </div>

                                    <div onClick={() => (alert("user: chefhyp3"))} className="transition-all align-middle inline-block text-xs text-gray-500 border border-slate-600 rounded-xl p-1 px-3 space-x-2 hover:bg-[#2a292b] hover:text-violet-200">
                                        <FontAwesomeIcon icon={faDiscord}/>
                                        <span>Discord</span>
                                    </div>
                                </div>
                            </div>
                        </motion.div>

                        <motion.div
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0, transition: { ease: "linear", delay: 1.5, duration: 0.5}}}
                            exit={{ opacity: 0, y: 50 }}
                            className="transition-all flex flex-col mt-8 w-full border border-slate-600/40 rounded-xl p-2 hover:bg-[#2a292b]"
                        >
                            <div className="inline-block text-white font-semibold space-x-2"><FontAwesomeIcon icon={faScroll}/><span>Knowledges</span></div>
                            <div className="flex flex-wrap gap-x-2">
                                {Object.keys(skills).map((skill, index) => ( 
                                    <div>
                                        <div className="inline-block mb-2 py-0.5 px-1.5 bg-blue-50 border text-xs font-medium rounded-lg bg-indigo-500/30 border-indigo-500 text-indigo-400"> {skills[index][0]} - {skills[index][1]}%</div>
                                    </div>
                                ))}
                            </div>
                        </motion.div>

                    </motion.div>
                </main>

            </AnimatePresence>

        </>
    )
}

export default Main;